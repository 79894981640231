@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/shared";
@import "./assets//scss/header";
@import "./assets/scss/footer";
@import "./assets/scss/catcarousel";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cover {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slider-img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100vh;

  @media screen and (max-width: $small) {
    height: calc(100vh / 2);
  }

  @media screen and (min-width: $small) and (max-width: $medium) {
    height: calc(100vh / 3 * 2);
  }
}

.ysda-btn-primary {
  border-color: $color-primary;
  background-color: $color-primary;
  color: #fff;
  border-radius: 3px;

  &:hover {
    border-color: $color-primary-dark;
    background-color: $color-primary-dark;
    color: #fff;
  }
}

.ysda-btn-secondary {
  border-color: $color-primary;
  background-color: transparent;
  color: $color-primary;
  border-radius: 0px;

  &:hover {
    border-color: $color-primary-dark;
    color: $color-primary-dark;
  }
}

.home-band {
  background-color: #fff;
  padding: 8rem 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    text-align: center;
  }
  p {
    max-width: 715px;
    text-align: center;
    margin-bottom: 2.25em;
  }
}

.volontaire {
  background-color: rgba($color: #000000, $alpha: 0.5);
  color: #fff;
}

.partner-item {
  border: 1px solid #D9D9D9;
  margin: 0px;
  padding: 10px 30px;
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 140px;
  }
}

.counters {
  background-color: $color-primary-dark;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.text-primary {
  color: $color-primary!important;
}

.project-img {
  width: 80%;
  object-fit: cover;

  @media (max-width: 992px){
    max-height: 350px;
  }

  @media (min-width: 992px){
    min-height: 650px;
  }
}

@media (min-width: 992px){
  .projects-text {
    margin-left: -150px;
  }
}

.main-content {
  min-height: 100vh;
}

.carousel-caption {
  margin-bottom: 15px;
}
.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.carousel-caption {
  /* 
  position: absolute;
  left: 15%;
  top: 6.75rem;
  bottom: 2.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  */
  .my-caption {
    background-color: rgba($color: #000, $alpha: 0.15);
    /* background-color: transparent;
    padding: 3.25rem 1.25rem; */
    padding: 1.25rem;
    border-radius: 5px;

    &.transparent {
      background-color: transparent!important;
    }
    /* @media screen and (min-width: $medium) {
      max-width: 75%;
      text-align: left;

      p {
        margin-top: 2.25rem;
        margin-bottom: 2.25rem;
      }
    }

    @media screen and (min-width: $large) {
      max-width: 50%;
    } */
  }
} 

.bg-white-light {
  background-color: rgba($color: #fff, $alpha: 0.6);
}

.avatar-lg {
  object-fit: cover;
  width: 200px!important;
  height: 200px!important;
  border-radius: 50%;
  margin: 1em;

  &:hover {
    transform: scale(1.1);
    border: 4px solid $color-primary;
  }
}

.owl-item.active.center .avatar-lg {
  transform: scale(1.1);
  border: 4px solid $color-primary;
}

.team-filter-item {
  padding: 15px 12px;
  background-color: #fff;
  color: orange;
  font-weight: 500;
  box-shadow: 0 1px 3px 0px rgba($color: #000000, $alpha: 0.15);
  cursor: pointer;
  margin: 5px;
  text-align: center;

  &.active {
    color: #fff;
    background-color: orange;
  }
}

.team-social-media {
  padding-bottom: 12px;
  padding-top: 8px;
  a {
    font-size: large;
    margin-right: 12px ;
    display: inline-block;
    padding: 5px 12px;
    border: 1px solid $color-primary-dark;
  }
}

.team-member-detail {
  display: none;

  *{
    text-align: center;
  }
}

.owl-item.active.center .team-member-detail {
  display: block;
}

.rel-art, .event-card {
  width: 100%;
  background: #fff;
  border-radius: .2rem;
  box-shadow: 0 0.1rem 0.3rem #05162833;
  display: flex;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: box-shadow .42s cubic-bezier(.33,1,.68,1);

  &:hover {
    box-shadow: 0 0.8rem 2.4rem #05162833;
  }
}

.rel-art {
  height: 100%;
  flex-direction: column;
  
  .image {
    width: 100%;
    height: 300px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .dsc {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.5rem 1rem;
    position: relative;
  }
}

.event-card {
  margin-bottom: 22px;
  img {
    width: 100%;
    object-fit: cover;
  }
  .dsc {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    position: relative;
  }
}

.thematic-axe-cover {
  width: 80%;
  min-height: 600px;
  object-fit: cover;
}

.job-header {
  color: $color-primary;
}

.admin-menu {
  border-radius: 5px;

  .menu-item {
    display: block;
    padding: 8px 12px;
    border-radius: 5px;
    color: rgb(248, 249, 250);
    text-decoration: none;

    &.active {
      background-color: rgba($color: #fff, $alpha: 0.15);
    }
  }
}

.article-list {
  margin-top: 1.5em;

  .article-item {
    display: flex;
    position: relative;

    img {
      width: 100px;
      height: 80px;
      object-fit: cover;
    }

    .article-details {
      padding-left: 0.5em;
      flex-grow: 1;
    }

    .actions {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      align-items: center;
      justify-content: flex-end;
      background-color: rgba($color: #000000, $alpha: 0.15);
      z-index: 9;
      display: none;
    }

    &:hover .actions {
      display: flex;
    }
  }
}

.article-full-page {
  @media screen and (min-width: $medium) {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  @media screen and (min-width: $large) {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }
}

.job-card {
  padding: 0px;
  background-color: #fff;
  width: 75%;
  height: 100%;
  min-height: 350px;
  box-shadow: 0 0.1rem 0.3rem #05162833;
  border-radius: 3px;
  margin: 0.75rem;

  &.shift-top {
    @media screen and (min-width: 1200px) {
      margin-top: -1.75rem;
      z-index: 5;
    }
  }

  .header {
    padding: 16px 12px;
    margin-bottom: 1rem;
    text-align: center;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.15);
    background-color: $color-primary;
    color: #fff;
  }

  .header + div {
    padding: 0px 0.5em;
  }

  a {
    text-decoration: none;
    font-size: larger;
  }
}

.post-comments {
  margin-top: 5rem;
  form {
    h4 {
      text-align: center;
      background-color: #fff;
      padding: 0.5rem;
    }
    background-color: rgba($color: #fff, $alpha: 0.55);
  }
}

.post-comment {
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  .content {
    flex-grow: 1;
  }
}

.hand-pointer {
  color: $color-primary;
  padding-right: 12px;
}

.res-flter-form {
  padding: 8px 12px;
  border-radius: 5px;
  
  .form-inputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .filter-item {
      flex: 1;
    }
  }

  .btn.btn-link {
    text-decoration: none;
  }
}

.res-list {
  padding: 8px 0px;

  .res-item {
    width: 100%;
    flex-grow: 1;
    background-color: rgba($color: #000, $alpha: 0.1);
    padding: 0.75rem;
    border-radius: 5px;

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.no-link {
  text-decoration: none;
}

.h-half {
  height: calc(100vh / 2);
}
.blog-carousel {
  // margin-left: -6.25rem;
  // margin-right: -6.25rem;

  img {
    width: 100%; 
    height: calc(100vh / 2);
    object-fit: cover;
  }

  .content-description {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000, $alpha: 0.15);
    z-index: 5;
    padding-left: 6.25rem;
    padding-right: 6.25rem;
    color: #fff;
  }

  .owl-dots {
    position: absolute;
    left: 0px;
    width: 100%;
    bottom: 1.25rem;

    .owl-dot span {
      background-color: rgba($color: #000, $alpha: 0.3)!important;
    }
    .owl-dot.active span {
      background-color: orange!important;
    }
  }
}

.blogpost-cover-image {
  width: 100%;
  position: relative;

  img {
    width: 100%; 
    height: calc(100vh / 2);
    object-fit: cover;
  }

  .content-description {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: rgba($color: #000, $alpha: 0.15);
    z-index: 5;
    padding-left: 6.25rem;
    padding-right: 6.25rem;
    padding-bottom: 1.75rem;
    color: #fff;
  }
}

.btn.btn-blog-slider {
  border: 2.5px solid orange;
  background-color: transparent;
  color: #fff;
  border-radius: 0px;

  &:hover, &:active {
    background-color: orange;
  }
}

.blog-post-tag {
  color: $color-primary!important;
}

.color-primary {
  color: $color-primary!important;
}

.share-socials {
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  width: 100%;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;

  a, button {
    margin: 0.15rem 0.25rem;
  }
}

.carourel-partners .owl-item.active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cover-get-involve {
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: calc(100vh - 85px);
    object-fit: cover;
  }

  .text-block {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    width: 50%;
    color: #F3F3F3;

    @media screen and (max-width: $small) {
      width: 100%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: center;
    }
  }
}

@mixin ysda-btn-outline ($color) {
  border: 2.5px solid $color;
  background-color: transparent;
  border-radius: 0px;

  &:hover, &:active {
    background-color: $color;
    color: #fff;
  }
}

@mixin ysda-btn-animate ($color) {
  border: 2.5px solid $color;
  background-color: $color;
  color: #fff;
  border-radius: 0px;

  &:hover, &:active {
    transform: scale(0.95);
  }
}

.btn.ysda-btn-outline.orange {
  @include ysda-btn-outline(orange)
}

.btn.ysda-btn-outline.green {
  @include ysda-btn-outline($color-primary)
}

.btn.ysda-btn-outline.purple {
  @include ysda-btn-outline(purple)
}

.btn.ysda-btn-animate.orange {
  @include ysda-btn-animate(orange)
}

.btn.ysda-btn-animate.green {
  @include ysda-btn-animate($color-primary)
}

.btn.ysda-btn-animate.purple {
  @include ysda-btn-animate(purple)
}

.mybtn-carousel {
  padding: 0.5em 1.25em;
}