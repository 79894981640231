$header-color: rgba($color: #fff, $alpha: 0.8);
$header-bg: rgba($color: #000, $alpha: 0.15);
$menu-color: rgba($color: #000000, $alpha: 0.75);

header {
    background: $header-bg;
    color: $header-color;
    position: absolute;
    top: 0;
    z-index: 9;
    width: 100%;
    padding: 0px 28px;

    nav {
        display: flex;
        align-items: center;
        height: 84px;
        // border-top: 1px solid $header-color;
        // padding-top: 12px;

        img {
            width: 150px;
            // height: 40px;
            margin: 22px 0px;
        }

        .nav-item {
            padding-left: 12px;
            padding-right: 12px;
            color: #fff;
            font-weight: 500;
            text-transform: capitalize;
            text-decoration: none;
            align-items: center;
            height: 100%;
            /* border-bottom-width: 2px;
            border-bottom-style: solid;
            border-bottom-color: transparent; */

            &:hover, &.active {
                color: $color-primary;
                /* border-bottom-color: $color-primary; */
            }

            @media screen and (max-width: $small) {
                display: none;
            }
            @media screen and (min-width: $medium) {
                display: flex;
            }
        }

        .nav-toggle {
            @media screen and (max-width: $small) {
                display: inline-block;
            }
            @media screen and (min-width: $small) {
                display: none;
            }
        }
    }
}

.offcanvas-header {
    h1 {
        padding-left: 0px 5px;
        letter-spacing: 5px;
        span {
            color: $color-primary;
        }
    }
}

.nav-item-pop, .sidebar-nav-item {
    display: block;
    padding: 8px 5px;
    text-decoration: none;
    color: $menu-color;

    &:hover {
        color: #000000;
        font-weight: 500;
    }
}

.nav-item-pop {
    width: 200px;
    padding-left: 12px;
    &:hover {
        background-color: rgba($color: #000, $alpha: 0.15);
        border-radius: 5px;
    }
}

.sub-nav {
    margin: 0px 5px;
    padding: 5px 12px;
    background-color: rgba($color: #000000, $alpha: 0.05);
}

.menu-popup-header {
    background-color: $color-primary;
    color: #fff;
}

.sticky {
    background-color: $header-color;
}

.header-secondary {
    .banner {
        width: 100%;
        max-height: 250px;
        object-fit: cover;
    }

    header {
        position: relative;
        color: $menu-color;
        background-color: #F3F3F3;
        padding: 0px 28px;

        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: 0 1px 3px rgba($color: #000, $alpha: 0.15);
        }
    }  

    .nav-item {
        color: $menu-color;
        border-bottom-color: transparent;

        &:hover, &.active {
            color: $color-primary;
            font-weight: 500;
        }
    }
}

.my-bread-crumb {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.header-btn-link {
    color: orange;
    border: 1px solid orange;
    padding: 8px 12px;
    text-decoration: none;

    &:hover {
        background-color: orange;
        color: #fff;
    }
}