.catagory-slyder {
    .owl-carousel .owl-dots {
        margin-top: 5px;
        text-align: center;
    }

    .owl-carousel .owl-dot {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        width: 20px;
        height: 20px;
        background-color: #D6D6D6!important;
        transition: .5s;
    }

    .owl-carousel .owl-dot.active{
        width: 40px;
        background-color: $color-primary!important;
        span {
            background: $color-primary!important;
        }
    }

    .owl-carousel .owl-item {
        padding: 12px;

        .category-text {
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    .owl-carousel .owl-item:hover .category-text {
        box-shadow: 0 0 30px #CCCCCC;
    }
}

.carourel-team {
    position: relative;
    .owl-prev, .owl-next {
        span {
            font-size: x-large;
            padding: 0px 8px;
        }
    }

    .owl-stage-outer {
        margin-top: 64px;
    }

    .owl-nav {
        position: absolute;
        top: 0;
        width: 100%;
        margin: 0px;
    }
}