$color-primary: #00AD79;
$color-primary-dark: #067050;
$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1144px;

.br-0 {
    border-radius: 0px!important;
}

.text-justify {
    text-align: justify;
}

.lettrine {
    font-size: xx-large;
    font-weight: 500;
}

.img-container {
    img {
        width: 100%;
    }
    .caption {
        width: 100%;
        text-align: center;
        margin: 0px;
        margin-top: 8px;
        font-size: larger;
        color: rgba($color: #000000, $alpha: 0.5);
    }
}

.img-article {
    border-radius: 12px;
}

.timestamp {
    width: 100%;
    display: flex;
    align-items: center;
}

.toc {
    position: sticky;
    top: 100px;
    display: flex;
    flex-direction: column;
    padding: 12px 0px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0px rgba($color: #000000, $alpha: 0.15);

    @media screen and (max-width: $medium) {
        display: none;
    }

    .header {
        padding: 8px 15px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.15);
    }
    .body {
        padding-top: 15px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            display: block;
            a {
                display: block;
                padding: 0.75rem 1rem 0.75rem 2rem;
                border-left: 4px solid #fff;
                text-decoration: none;

                &.current {
                    background-color: rgba($color: #0000ff, $alpha: 0.15);
                    border-left-color:#0000ff;
                    font-weight: 500;
                }
            }
        }
    }
}

.form-group {
    padding: 8px 0px;
}

.img-preview {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

a.ysda-link, button.ysda-link {
    color: $color-primary!important;
}

.btn-ysda  {
    color: #fff;
    background-color: $color-primary;
    border: 1px solid $color-primary;
    
    &:hover, &:active {
        color: #fff;
        background-color: $color-primary-dark!important;
        border: 1px solid $color-primary-dark!important;
    }
}

@mixin custom-button ($bg, $bg-dark) {
    color: #fff;
    background-color: $bg;
    border: 1px solid $bg;
    
    &:hover, &:active {
        color: #fff;
        background-color: $bg-dark!important;
        border: 1px solid $bg-dark!important;
    }
}

.btn-ysda  {
    @include custom-button($color-primary, $color-primary-dark)
}

.btn-ysda-orange  {
    @include custom-button(rgb(254, 166, 4), rgb(219, 144, 4));
}

.btn-ysda-yellow  {
    @include custom-button(rgb(211, 211, 0), rgb(183, 183, 1));
}