.btn-social-link {
    display: inline-block;
    border: 1px solid $color-primary;
    color: $color-primary;
    border-radius: 0px;
    margin-right: 5px;
  
    &:hover {
      background-color: $color-primary;
      color: #fff;
    }
}
  
.link-ysda, .link-ysda:visited {
    color: $color-primary;
    text-decoration: none;
}

.link-ysda:hover, .link-ysda:active {
    color: $color-primary-dark;
}

footer .form-control {
    border-radius: 0px!important;
    &:focus {
        background-color: #fff;
        border-color: rgba($color-primary, 0.2);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba($color-primary, 0.2);
    }
}

footer .btn.btn-primary {
    border-radius: 0px!important;
    background-color: $color-primary;
    border-color: $color-primary;
    &:hover, &:active {
        background-color: $color-primary-dark;
        border-color: $color-primary-dark;
    }
}

footer {
    a {
        text-decoration: none;
    }

    li {
        padding-top: 12px;
    }

    h5 {
        font-weight: 400;
    }

    h6 {
        font-weight: 300;
    }
}